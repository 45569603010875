<template>
    <span
        v-show="!!text"
        @click.stop="handleCopyBtn"
        :type="buttonType"
        class="copy-btn" v-dbclick
    >{{ realBtnText }}</span>
</template>

<script>
export default {
    name: 'CopyText',
    props: {
        text: {
            type: String,
            default: "复制成功了"
        },
        btnText: {
            type: String,
            default: ""
        },
        buttonType: {
            type: String,
            default: "text"
        }
    },

    data () {
        return {}
    },

    computed: {
        realBtnText () {
            return this.btnText || this.text || "复制"
        }
    },

    methods: {
        handleCopyBtn () {
            const fine = this.text.pushToClipBoard();
            if (fine) this.$message({
                message: "复制成功",
                type: "success"
            });
            else this.$message({
                message: "复制失败, 请手动复制",
                type: "warning"
            })
        }
    }
}
</script>

<style lang="scss" scoped>
    .copy-btn {
        cursor: pointer;
        text-decoration: underline dotted;
    }
</style>